<template>
  <div v-if="loadingProfile && profile == null" class="ob-page">
    <ObLoading />
  </div>
  <div v-else class="ob-page">
    <div class="title">
      <p class="header">OwnersBucks</p>
      <img src="@/assets/icons/vip.png" class="vipImg" v-if="isVip"
        alt="VIP Tag"
      >
      <td v-if="isVip">
        <i class="fas fa-info-circle infoBtnVIP"
          v-tooltip.right="{content: 'As a VIP, you can convert OwnersBucks to Cash, as well as redeem them for tickets.', class:'ObTooltip vip-tooltip'}"
        >
        </i>
      </td>
    </div>
    <p class="text">
      At OwnersBox, earn OwnersBucks every time you play! Think of it as cash back—play any game, earn OwnersBucks, and redeem them for entry tickets.
    </p>
    <div class="redeemableGraphLarge">
      <RedeemableOBucks :ownersbucksTot="ownersbucks" :ownersbucksRedeemable="redeemable"
        :scale="1.3"
      />
    </div>
    <div class="buttonsContainer">
      <div style="flex: 1"></div>
      <router-link class="transactionHistoryLink" to="/transactions/ownersbucks">
        View Transaction History
        <i class="fal fa-chevron-right" style="margin-left: 10px;"></i>
      </router-link>
    </div>
    <div class="buttonsContainer">
      <button class="ob-btn btnOption" @click="openRedeemForTicketsModal()" :disabled="loadingProfile">
        <img src="@/assets/icons/ticket.png" class="ticketImg" alt="OwnersBox Ticket Icon">
        <p>Redeem For Tickets</p>
        <i class="fas fa-long-arrow-right"></i>
      </button>
      <button v-if="isVip" class="ob-btn btnOption" @click="openConvertCurrencyModal()"
        :disabled="loadingProfile"
      >
        <img src="@/assets/icons/real-money.png" class="dollarImg" alt="Cash icon">
        <p>Convert To Cash</p>
        <i class="fas fa-long-arrow-right"></i>
      </button>
    </div>
    <div class="info">
      <p class="rates">OwnersBucks Rates</p>
      <div class="infoContainer">
        <div class="infoInner">
          <div class="tableHeading">
            <div>EARN</div>
            <div>REDEEM</div>
          </div>
          <div class="rateTable">
            <table v-if="ownersbucksSettings" aria-label="OwnersBucks Rates">
              <tr>
                <td><img src="@/assets/contestbadge/player_picks.png" class="badgeImg" alt="Player Picks Logo"></td>
                <td class="rateText"> Player Picks</td>
                <td class="earn">{{ ownersbucksSettings.propsEarnRate * 100 }}%</td>
                <td class="redeem">{{ ownersbucksSettings.propsRedeemRate * 100 }}%</td>
              </tr>
              <tr>
                <td><img src="@/assets/contestbadge/salarycap.png" class="badgeImg" alt="Salary Cap Logo"></td>
                <td class="rateText">Salary Cap</td>
                <td class="earn">{{ ownersbucksSettings.salaryCapEarnRate * 100 }}%</td>
                <td class="redeem">
                  <div class="percentInfoContainer">
                    {{ ownersbucksSettings.salaryCapRedeemRate * 100 }}%
                    <i class="fas fa-info-circle infoBtn" @click="openSalaryCapModal()"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <td><img src="@/assets/contestbadge/lightning_lineups.png" class="badgeImg" alt="Lightning Lineups Logo"></td>
                <td class="rateText">Lightning Lineups</td>
                <td class="earn">{{ ownersbucksSettings.llEarnRate * 100 }}%</td>
                <td class="redeem">{{ ownersbucksSettings.llRedeemRate * 100 }}%</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="infoInner">
          <p class="info1">EARN MORE BY</p>
          <p class="info2">PLAYING MORE</p>
          <p class="info3">OwnersBucks loyalty program rewards you for being a dedicated player!</p>
          <div style="flex: 1;"></div>
          <a v-if="ownersbucksSettings" class="learnMore" :href="ownersbucksSettings.learnLinkUrl"
            target="_blank"
          >
            LEARN MORE
          </a>
          <img src="@/assets/icons/bundled.png" class="cashImg" alt="Pile of OwnersBucks">
        </div>
      </div>
    </div>
    <ConversionInfoModal />
    <OwnersBucksConvertToCashModal />
  </div>
</template>

<script>
import ObAccountApi from '@/api/ObAccountApi';
import EventBus from '@/event-bus';
import ConversionInfoModal from '../Profile/ConversionInfoModal.vue';
import RedeemableOBucks from '@/components/charts/RedeemableOBucks.vue';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';
import OwnersBucksConvertToCashModal from '@/components/modals/Financial/ownersbucks/OwnersBucksConvertToCashModal.vue';

export default {
  components: {
    ConversionInfoModal,
    RedeemableOBucks,
    ObLoading,
    OwnersBucksConvertToCashModal,
  },
  data() {
    return {
      isVip: false,
      profile: null,
      ownersbucksSettings: null,
      loadingProfile: false,
    };
  },

  created() {
    this.loadProfile();
    EventBus.$on('LOAD_USER_PROFILE', this.loadProfile);
  },

  computed: {
    ...mapState(['ownersbucksValue', 'userId']),

    ownersbucks() {
      if (!this.ownersbucksValue) {
        return null;
      }

      return (this.ownersbucksValue / 100).toFixed(2);
    },

    redeemable() {
      if (!this.profile?.convertible) {
        return null;
      }

      let redeemableVal = parseFloat(this.profile?.convertible.replace(/,/g, ''));

      if (redeemableVal > this.ownersbucksValue / 100) {
        redeemableVal = this.ownersbucksValue / 100;
      }

      return redeemableVal.toString();
    },
  },

  methods: {
    loadProfile() {
      this.loadingProfile = true;
      ObAccountApi.getProfile(this.userId).then((response) => {
        this.profile = response;
        this.isVip = response.canConvertOBToCash;
        this.ownersbucksSettings = response.ownersBucksSettings;
      }).finally(() => {
        this.loadingProfile = false;
      });
    },

    openConvertCurrencyModal() {
      EventBus.$emit('CONVERT_OWNERSBUCKS_TO_CASH_OPEN', this.profile);
    },

    openRedeemForTicketsModal() {
      EventBus.$emit('REDEEM_FOR_TICKETS_OPEN', this.profile);
    },

    openSalaryCapModal() {
      EventBus.$emit('OPEN_CONVERSION_INFO_MODAL');
    },

  },
};
</script>

<style lang="scss">
// Global style for this
.vip-tooltip {
  background: var(--light700) !important;
  * {
    border-color: var(--light700) !important;
  }
}
</style>

<style lang="scss" scoped>
.ob-page {
  flex: 1;
  padding-top: 45px;
  font-family: 'Archivo', sans-serif;
  width: 675px;
}

.header {
  font-size: 20px;
  font-weight: bold;
}

.text {
  font-size: 16px;
  line-height: 21px;
  color: var(--obcolor-font-secondary);
}

.redeemableGraphLarge {
  padding: 10px 0 0 0;
  > div {
    box-sizing: border-box;
  }
  border: 1px solid var(--light700);
  border-radius: 5px;
}

.buttonsContainer {
  display: flex;
  gap: 15px;
  width: 100%;
  margin-top: 15px;
  align-items: center;
}

.btnOption {
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  height: 75px;
  border-radius: 4px;
  background: var(--obcolor-background-6);
  color: var(--obcolor-font-primary);
  padding: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 15px;

  > p {
    flex: 1;
    text-align: left;
  }

  .ticketImg{
    width: 27px;
    margin-right: 15px;
  }

  .dollarImg{
    width: 24px;
    margin-right: 15px;
  }

  .arrowImg{
    width: 16px;
  }

  &:hover:not(:disabled) {
    background: var(--obcolor-background-4) !important;
  }
}

.info{
  background: var(--obcolor-background-6);
  border-radius: 4px;
  margin-top: 15px;
  padding: 1px 15px 22px 15px;
  height: 258px;
  display: flex;
  flex-direction: column;
}

.infoContainer{
  flex: 1;
  display: flex;
  align-items: stretch;
  gap: 15px;
}

.infoInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: var(--obcolor-background-4);
  border-radius: 4px;
  position: relative;
  padding: 20px 15px;
}

.infoInner p {
  margin: 0;  /* Remove default margin */
}

.info1{
  font-size: 16px;
  font-weight: 900;
}

.info2{
  font-size: 24px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--obcolor-ob-blue);
}

.info3{
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  line-height: 16px;
  color: var(--light200);
  margin-top: 15px;
}

.learnMore{
  border-radius: 4px;
  border: 1.5px solid var(--obcolor-ob-blue);
  background-color: transparent;
  color: var(--obcolor-font-primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 25px;
}

.learnMore:hover{
  background-color: var(--obcolor-accent-light);
}

.cashImg{
  position: absolute; /* Use absolute positioning */
  bottom: 0; /* Align to the bottom */
  right: 0;
  width: 80px;
  height: auto;
}

.rates{
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  margin: 5px 0;
}

.badgeImg{
  width: 27px;
  height: auto;
  margin-left: 5px;
}

.rateText{
  font-size: 14px;
  line-height: 30px;
  padding-left: 5px;
  color: var(--obcolor-font-primary);
  white-space: nowrap;
  width: 120px;
}

.earn, .redeem{
  font-size: 14px;
  line-height: 30px;
  box-sizing: border-box;
  width: 60px;
  white-space: nowrap;
  padding-left: 2px;
}

.rateTable{
  background: var(--obcolor-background-2);
  border-radius: 4px;
  padding: 2px;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  > table {
    height: 100%;
    width: 100%;
  }
}

.vipImg{
  width: auto;
  height: 30px;
  margin-left: 10px;
}

.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: -5px;
}

.tableHeading{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  padding-right: 6px;
  > div {
    box-sizing: border-box;
    width: 60px;
    padding-left: 4px;
    text-align: left;
  }
}

.infoBtn, .infoBtnVIP {
  font-size: 16px;
  color: var(--obcolor-font-secondary);
  margin-right: auto;
  cursor: pointer;
}

.infoBtn {
  margin-left: 2px;
  margin-right:3px;
  padding-right: 0px;
  margin-top: -3px;
}

.infoBtnVIP {
  margin-left: 10px;
}

.infoBtn:hover, .infoBtnVIP:hover {
    color: var(--obcolor-font-primary);
}

.percentInfoContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.transactionHistoryLink {
  font-size: 14px;
  color: var(--obcolor-ob-blue);
  text-align: right;
  padding: 10px 0;

  &:hover {
    text-decoration: underline;
  }
}

::v-deep {
  .redeemableGraphLarge .svgContainer {
    .ownersbucksRedeemable .ownersbucksText {
      font-size: 32px;
      img {
        height: 18px;
      }
    }
  }
}
</style>
