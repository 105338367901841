<template>
  <div>
    <div class="depositAmountScreen">
      <p class="depositText">Select deposit amount (USD)</p>
      <div class="dollarAmount">
        <div v-for="(option, idx) in depositOptions" :key="idx" @click="selectAmount(option.value)"
          class="depositAmount"

          :class="{
            depositAmountSelected:
              (option.value === 'custom' && customAmount) ||
              (option.value !== 'custom' && amountCents == option.value && !customAmount)
          }"
        >
          <div v-if="option.label">{{ option.label }}</div>
          <div v-else style="text-transform: capitalize;">{{ option.value }}</div>
        </div>
      </div>

      <p class="depositText">Amount to be deposited</p>
      <div class="inputContainer" @click="selectAmount('custom')">
        <i class="fal fa-dollar-sign"></i>
        <input v-model="customDepositValue" @input="updateCustomAmount" ref="customInput"
          maxlength="5" placeholder="Amount"
        >
        <div v-if="!userInfo.hasDeposited" class="bonusInputContainer">
          <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks Icon">
          <div class="bonusInput">+{{ bonusValue }} Bonus</div>
        </div>
      </div>
      <div class="minMaxAmount">Min $10, Max $1000 / Deposit</div>
    </div>
    <ErrorPanel v-if="submitError" :message="submitError" />

    <button v-if="onlineBanking" class="ob-btn submitBtn" :disabled="isDisabled"
      @click="depositFiserv()"
    >
      <i v-if="waitingForResponse" class="fad fa-spinner-third fa-spin"></i>{{ buttonText }}
    </button>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  components: {
    ErrorPanel,
  },

  props: {
    userInfo: {type: Object, default: null},
    userAccounts: {type: Object, default: null},
    onlineBanking: {type: Boolean, default: false},
    depositCredit: {type: Boolean, default: false},
  },

  data() {
    return {
      amountCents: 2500,
      customDepositValue: 25,
      customAmount: false,
      waitingForResponse: false,
      submitError: '',
      depositOptions: null,
      errorMessage: '',
    };
  },

  created() {
    this.getDepositOptions();
  },

  computed: {
    amount() {
      return this.amountCents / 100;
    },

    buttonText() {
      if (this.waitingForResponse) {
        return 'Submitting...';
      } else if (this.amount < 10) {
        return 'Minimum deposit is $10';
      } else if (this.amount > 1000) {
        return 'Maximum deposit is $1000';
      } else if (this.userInfo && !this.userInfo.hasDeposited) {
        return `Deposit $${this.amount} (+${this.bonusValue} Bonus)`;
      } else {
        return `Deposit $${this.amount}`;
      }
    },

    bonusValue() {
      if (!this.amount) {
        return;
      }

      const amount = parseFloat(this.amount);
      return amount > 500 ? 500 : Math.floor(amount);
    },

    isDisabled() {
      return this.amount < 10 || this.amount > 1000 || this.waitingForResponse;
    },
  },

  methods: {
    getDepositOptions() {
      ObPaymentApi.getDepositOptions()
          .then((response) => {
            this.depositOptions = response.options.filter((option) => option.value !== 25000);
          }).catch((_error) => {
            // Do nothing
          });
    },

    selectAmount(amt) {
      if (amt === 'custom') {
        this.$refs.customInput.focus();
        this.amountCents = null;
        this.customDepositValue = null;
        this.customAmount = true;
      } else {
        this.customAmount = false;
        this.amountCents = amt;
        this.customDepositValue = (amt / 100).toString();
      }

      if (this.depositCredit) {
        this.$emit('setDepositValue', this.amount);
      }
    },

    updateCustomAmount() {
      const customValue = this.customDepositValue.replace(/[^\d]/g, '');
      this.customDepositValue = customValue;
      this.amountCents = parseInt(customValue || '0', 10) * 100;

      if (!this.depositOptions.some((option) => option.value === this.amountCents)) {
        this.customAmount = true;
      } else {
        this.customAmount = false;
      }

      if (this.depositCredit) {
        this.$emit('setDepositValue', this.amount);
      }
    },

    depositFiserv() {
      this.waitingForResponse = true;
      ObPaymentApi.depositFiserv(this.userAccounts.id, this.amount.toFixed(2), this.amountCents)
          .then((response) => {
            // Successful deposit, refresh balance
            let tCoupon;
            const tid = String(new Date().getTime());
            const tAmount = parseFloat(this.amount.toFixed(2));
            this.waitingForResponse = false;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('DEPOSIT_SUBMIT_SUCCESS');

            let firstTimeDeposit = false;
            if (response.isFirstDeposit) {
              tCoupon = 'FIRST_DEPOSIT';
              firstTimeDeposit = true;
            }

            EventBus.$emit('OPEN_DEPOSIT_MODAL', this.amount, firstTimeDeposit, response.transactionId);

            this.$Analytics.trackDeposit(tid, tAmount, tCoupon, true);
          }).catch((error) => {
            this.waitingForResponse = false;
            if (error?.response?.data) {
              this.submitError = error.response.data.errorMessage;
            } else {
              this.submitError = 'Failed to deposit amount';
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>