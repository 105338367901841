<template>
  <div class="container">
    <div v-if="selectedMethod && !onBoardingStep && !depositOnlineBank" class="arrowContainer">
      <a class="backBtn" @click="unsetSource"><i class="far fa-chevron-left"></i></a>
    </div>
    <div v-if="showFirstTimeDeposit">
      <FirstDepositText />
    </div>
    <template v-if="!selectedMethod">
      <p class="depositText">Choose your deposit method</p>
      <div v-for="(source, index) in sources" :key="index" class="checkboxContainer"
        @click="toggleSource(source)"
      >
        <div class="iconContainer">
          <i v-if="source.iconSrc" :class="source.iconSrc"></i>
          <img v-else-if="source.imgUrl && theme === 'dark'" :src="source.imgUrl" class="methodIcon"
            :alt="source.text"
          >
          <img v-else-if="source.imgUrl && theme === 'light'" :src="source.imgUrlLight" class="methodIcon"
            :alt="source.text"
          >
          <div>
            <div>{{ source.text }}</div>
            <div v-if="source.subtitle" class="methodSubtitle">{{ source.subtitle }}</div>
          </div>
        </div>
        <i class="far fa-chevron-right"></i>
      </div>
    </template>
    <template v-else>
      <DepositCreditMethod v-if="selectedMethod.text === 'Debit / Credit Card'" :locationInfo="locationInfo" :userInfo="userInfo" />
      <GiftCardMethod v-else-if="selectedMethod.text === 'OwnersBox Gift Card'" :locationInfo="locationInfo" :userInfo="userInfo" />
      <DepositOnlineBankingMethod v-else-if="selectedMethod.text == 'Online Banking'" :userInfo="userInfo" @isOnlineBankDeposit="isOnlineBankDeposit" />
    </template>
  </div>
</template>
<script>
import DepositCreditMethod from './DepositCreditMethod.vue';
import GiftCardMethod from './GiftCardMethod.vue';
import {mapState} from 'vuex';
import DepositOnlineBankingMethod from './DepositOnlineBankingMethod.vue';
import FirstDepositText from './FirstDepositText.vue';

export default {
  components: {
    DepositCreditMethod,
    GiftCardMethod,
    DepositOnlineBankingMethod,
    FirstDepositText,
  },
  props: {
    locationInfo: {type: Object, default: null},
    userInfo: {type: Object, default: null},
    onBoardingStep: {type: Boolean, default: false},
  },

  data() {
    return {
      selectedMethod: null,
      depositOnlineBank: false,
      sources: [
        {
          iconSrc: 'fal fa-university',
          text: 'Online Banking',
          subtitle: 'USA Only',
          selected: false,
        },
        {
          iconSrc: 'fal fa-credit-card',
          text: 'Debit / Credit Card',
          selected: false,
        },
        {
          imgUrl: require('@/assets/oblogo_transparent.png'),
          imgUrlLight: require('@/assets/oblogo_transparent_light.png'),
          text: 'OwnersBox Gift Card',
          selected: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(['theme']),

    showFirstTimeDeposit() {
      return this.userInfo && !this.userInfo.hasDeposited && this.selectedMethod?.text != 'Online Banking';
    },
  },
  methods: {
    toggleSource(source) {
      this.sources.forEach((s) => (s.selected = s.text === source.text));
      this.selectedMethod = source;
      this.$emit('isSelectedMethod', true);
    },
    unsetSource() {
      this.sources.forEach((s) => (s.selected = false));
      this.selectedMethod = null;
      this.$emit('isSelectedMethod', false);
    },
    isOnlineBankDeposit(boolean) {
      this.depositOnlineBank = boolean;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
.container {
  line-height: 30px;
}
</style>
