import * as braze from '@braze/web-sdk';
const Analytics = {};

Analytics.install = function(Vue, options) {
  const Analytics = {

    trackPageView(page) {
      // Braze only, GTM page views are automatically tracked using the plugin
      if (page && page !== '/') {
        const params = {page: page};
        braze.logCustomEvent('page_view', params);
      }
    },

    trackTournamentEntry(contest, success, promo, amt = 1, usedQuickEntry = false) {
      const eventName = success ? 'join_tournament' : 'join_tournament_failed';
      this._trackContestEntry(eventName, contest, promo, amt, usedQuickEntry);
    },

    trackHead2HeadEntry(contest, success) {
      const eventName = success ? 'join_contest' : 'join_contest_failed';
      this._trackContestEntry(eventName, contest, '');
    },

    // Helper Method, don't use directly
    _trackContestEntry(eventName, contest, promo, amt, usedQuickEntry) {
      if (!contest) {
        return;
      }

      const fee = Math.round(contest.feeValue / 100);
      const type = contest.realMoney ? 'realmoney' : 'ownersbucks';

      const gtmEvent = {
        event: 'interactionEvent',
        category: eventName,
        action: type,
        label: contest.sport + '_' + contest.playersMax + 'Team',
        value: fee,
        obContestId: contest.id,
        obContestSport: contest.sport,
        obContestPromo: promo,
      };

      const AFEventValue = {
        'amount': fee,
        'contest_id': contest.id,
        'num_of_lineups': amt,
        'real_money': contest.realMoney ? 'realmoney' : 'ownersbucks',
        'sport': contest.sport,
      };

      // These fields only exist on SalaryCap Contests
      if (contest.contestName) {
        gtmEvent.obContestName = contest.contestName;
        AFEventValue['contest_name'] = contest.contestName;
      }
      if (contest.contestStart) {
        gtmEvent.obContestStart = contest.contestStart;
      }

      Vue.prototype.$gtm.trackEvent(gtmEvent);

      if (eventName !== 'join_tournament_failed' && eventName !== 'join_contest_failed') {
        this._sendAFEvent(AFEventValue, eventName);
      }
    },

    trackLightningLineupsEntry(contest, success, promo) {
      const eventName = success ? 'join_LL' : 'join_LL_failed';
      this._trackLLEntry(eventName, contest, promo);
    },

    _trackLLEntry(eventName, contest, promo) {
      if (!contest) {
        return;
      }

      const type = contest.realMoney ? 'realmoney' : 'ownersbucks';
      const gtmEvent = {
        event: 'interactionEvent',
        category: eventName,
        action: type,
        label: contest.sport + '_' + contest.playersMax + 'Team',
        value: contest.fee,
        obContestId: contest.contestId,
        obContestLineupId: contest.lineupId,
        obContestPromo: promo,
        obContestSport: contest.sport,
        obContestLineupCount: contest.lineupCount,
        obContestPayout: contest.payout,
        obContestPlayers: JSON.stringify(contest.players),
        obContestMultiplier: contest.payoutMultiplier,
        obContestFailReason: contest.failureReason,
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);

      const eventValue = {
        'amount': parseFloat(contest.fee),
        'contest_id': contest.contestId,
        'real_money': contest.realMoney ? 'realmoney' : 'ownersbucks',
        'sport': contest.sport,
      };
      this._sendAFEvent(eventValue, eventName);
    },

    trackDeposit(id, amount, coupon, success) {
      const eventName = success ? 'deposit' : 'deposit_failed';
      this._trackDeposit(eventName, id, amount, coupon);
    },

    // Helper Method, don't use directly
    _trackDeposit(eventName, id, amount, coupon) {
      const gtmEvent = {
        event: 'purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: id,
              affiliation: 'creditcard',
              revenue: amount,
              coupon: coupon,
            },
          },
        },
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);
    },

    trackSignup(params, userId, success) {
      const eventName = success ? 'sign_up' : 'sign_up_failed';
      this._trackSignUp(eventName, params, userId);
    },

    trackSignupAttempt(params) {
      const eventName = 'sign_up_attempt';
      this._trackSignUp(eventName, params, '');
    },

    // Helper Method, don't use directly
    _trackSignUp(eventName, params, userId) {
      const gtmEvent = {
        event: 'interactionEvent',
        category: eventName,
        action: '',
        label: userId ? userId : '',
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);

      if (eventName === 'sign_up') {
        const eventValue = {
          'user_id': userId,
          'username': params.username,
          'email': params.email,
        };
        this._sendAFEvent(eventValue, eventName);
      };
    },

    trackVerify(userId, success) {
      const eventName = success ? 'verified' : 'verify_failed';
      this._trackVerify(eventName, userId);
    },

    trackVerifyCheckSSN(userId) {
      const eventName = 'verify_checkssn';
      this._trackVerify(eventName, userId);
    },

    // Helper Method, don't use directly
    _trackVerify(eventName, userId) {
      const gtmEvent = {
        event: 'interactionEvent',
        category: eventName,
        action: '',
        label: userId ? userId : '',
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);
    },

    trackAppLoad(user) {
      if (!user) {
        return;
      }
      const eventName = 'app_load';
      const balance = (user.balanceValue / 100).toFixed(2);

      const gtmEvent = {
        event: 'interactionEvent',
        category: eventName,
        action: '',
        label: user.id ? user.id : '',
        user_id: user.id ? user.id : '',
        obUserDateRegistered: user.created,
        obUserBalance: balance,
        obUserEmail: user.email,
        obUserFirstName: user.firstName,
        obUserLastName: user.lastName,
        obUserEvsVerified: user.isDepositVerified,
        obUsername: user.username,
        obUserHasDeposited: user.hasDeposited,
        obUserH2HEntryALL: user.leaguesCountALL,
        obUserH2HEntryNHL: user.leaguesCountNHL,
        obUserH2HEntryNFL: user.leaguesCountNFL,
        obUserH2HEntryNBA: user.leaguesCountNBA,
        obUserH2HEntryMLB: user.leaguesCountMLB,
        obUserSCEntryALL: user.leaguesCountScALL,
        obUserSCEntryNHL: user.leaguesCountScNHL,
        obUserSCEntryNFL: user.leaguesCountScNFL,
        obUserSCEntryNBA: user.leaguesCountScNBA,
        obUserSCEntryMLB: user.leaguesCountScMLB,
        obUserLLEntryALL: user.leaguesCountLLALL,
        obUserLLEntryNHL: user.leaguesCountLLNHL,
        obUserLLEntryNFL: user.leaguesCountLLMLB,
        obUserLLEntryNBA: user.leaguesCountLLNBA,
        obUserLLEntryMLB: user.leaguesCountLLMLB,
        obUserFirstContestDateNHL: user.firstContestNHL,
        obUserFirstContestDateNFL: user.firstContestNFL,
        obUserFirstContestDateNBA: user.firstContestNBA,
        obUserFirstContestDateMLB: user.firstContestMLB,
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);

      const brazeUser = braze.getUser();

      // Braze standard attributes tracking
      brazeUser.setFirstName(user.firstName);
      brazeUser.setLastName(user.lastName);
      brazeUser.setEmail(user.email);

      // Braze custom event and atributes tracking
      braze.logCustomEvent(eventName);
      brazeUser.setCustomUserAttribute('user.id', user.id);
      brazeUser.setCustomUserAttribute('user.date_registered', user.created);
      brazeUser.setCustomUserAttribute('user.balance', balance);
      brazeUser.setCustomUserAttribute('user.ownersbucks', user.ownersbucks);
      brazeUser.setCustomUserAttribute('user.evs_verified', user.isDepositVerified ? true : false);
      brazeUser.setCustomUserAttribute('user.username', user.username);
      brazeUser.setCustomUserAttribute('user.has_deposited', user.hasDeposited ? true : false);
      brazeUser.setCustomUserAttribute('user.h2h_entry_all', user.leaguesCountALL);
      brazeUser.setCustomUserAttribute('user.h2h_entry_nhl', user.leaguesCountNHL);
      brazeUser.setCustomUserAttribute('user.h2h_entry_nfl', user.leaguesCountNFL);
      brazeUser.setCustomUserAttribute('user.h2h_entry_nba', user.leaguesCountNBA);
      brazeUser.setCustomUserAttribute('user.h2h_entry_mlb', user.leaguesCountMLB);
      brazeUser.setCustomUserAttribute('user.salarycap_entry_all', user.leaguesCountScALL);
      brazeUser.setCustomUserAttribute('user.salarycap_entry_nhl', user.leaguesCountScNHL);
      brazeUser.setCustomUserAttribute('user.salarycap_entry_nfl', user.leaguesCountScNFL);
      brazeUser.setCustomUserAttribute('user.salarycap_entry_nba', user.leaguesCountScNBA);
      brazeUser.setCustomUserAttribute('user.salarycap_entry_mlb', user.leaguesCountScMLB);
      brazeUser.setCustomUserAttribute('user.ll_entry_all', user.leaguesCountLLALL);
      brazeUser.setCustomUserAttribute('user.ll_entry_nhl', user.leaguesCountLLNHL);
      brazeUser.setCustomUserAttribute('user.ll_entry_nfl', user.leaguesCountLLNFL);
      brazeUser.setCustomUserAttribute('user.ll_entry_nba', user.leaguesCountLLNBA);
      brazeUser.setCustomUserAttribute('user.ll_entry_mlb', user.leaguesCountLLMLB);
      brazeUser.setCustomUserAttribute('user.first_contest_date_nhl', user.firstContestNHL);
      brazeUser.setCustomUserAttribute('user.first_contest_date_nfl', user.firstContestNFL);
      brazeUser.setCustomUserAttribute('user.first_contest_date_nba', user.firstContestNBA);
      brazeUser.setCustomUserAttribute('user.first_contest_date_mlb', user.firstContestMLB);
    },

    trackUser(userId) {
      if (!userId) {
        userId = '';
      }

      AF('pba', 'setCustomerUserId', userId);
    },

    // AppsFlyer Web SDK
    _sendAFEvent(eventValue, eventName) {
      const event = {
        eventType: 'EVENT',
        eventValue: eventValue,
        eventName: eventName,
      };

      AF('pba', 'event', event);
    },

    trackAPKDownload() {
      const gtmEvent = {
        event: 'interactionEvent',
        category: 'apk_download',
        action: '',
      };

      Vue.prototype.$gtm.trackEvent(gtmEvent);
    },
  };

  Vue.prototype.$Analytics = Analytics;
};

export default Analytics;