<template>
  <div :class="onBoardingStep ? 'container' : 'ob-page container'">
    <h1 v-if="!checkingValidLocation && userInfo && !hasNotVerified && !isDepositMethodSelected">Deposit</h1>

    <div v-if="checkingValidLocation || loadingUserData || loadingUserState" class="loadingBox">
      <div><ObLoading :size="90" /></div>
      <div>Verifying your location...</div>
    </div>

    <div v-else-if="!checkingValidLocation && isLocationValid">
      <div v-if="!userInfo"><ObLoading /></div>

      <!-- In case user has not verified their account and enter deposit url during onboarding -->
      <div v-else-if="hasNotVerified && onBoardingStep">
        <h1>Deposit</h1>
        <div>You are not verified to make deposits. Go back to verify your account.</div>
      </div>

      <div v-else-if="hasNotVerified">
        <ReasonsToVerify />
        <router-link to="/verifyaccount" class="ob-btn" style="margin-top: 30px; height: 40px;">Continue</router-link>
      </div>

      <div v-else-if="userInfo && userInfo.isDepositVerified == false">
        <h1>Deposit</h1>
        <div>Your account is not permitted to make deposits.</div>
      </div>

      <DepositMethods v-else :locationInfo="locationInfo" :userInfo="userInfo"
        @isSelectedMethod="isSelectedMethod" :onBoardingStep="onBoardingStep"
      />
    </div>

    <!-- Failed Verification -->
    <div v-else-if="isRestricted && restrictedMessage === 'Verification Failed'" class="ob-box notAllowedBox">
      <div class="failedVerification">Verification Failed</div>
      <div style="margin-top: 20px;">
        Please contact
        <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com/hc/en-us/requests/new"
          rel="noopener"
        >customer service</a>
        to verify your identity in order to proceed.
      </div>
      <div>
        You will not be able to deposit or play real money contests until you verify your identity.
      </div>
      <div style="margin-top: 15px; margin-bottom: 20px;">
        If you need additional help, please visit
        <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com"
          rel="noopener"
        >support.ownersbox.com</a>
      </div>
      <router-link v-if="onBoardingStep" to="/start-playing" class="ob-btn">Explore</router-link>
      <router-link v-else to="/" class="ob-btn">Go to Lobby</router-link>
    </div>

    <!-- Location check error -->
    <div v-else-if="isRestricted" class="ob-box notAllowedBox">
      <div class="failedVerification">Ineligible to Deposit</div>
      <div>{{ restrictedMessage }}</div>
      <div style="margin-top: 10px; margin-bottom: 20px;">
        If you need additional help, please visit
        <a class="ob-hyperlink" target="_blank" href="https://support.ownersbox.com"
          rel="noopener"
        >support.ownersbox.com</a>
      </div>
      <router-link v-if="onBoardingStep" to="/start-playing" class="ob-btn">Explore</router-link>
      <router-link v-else to="/" class="ob-btn">Go to Lobby</router-link>
    </div>

    <div v-if="!checkingValidLocation && !loadingUserData && !loadingUserState && !isRestricted" class="termsOfService">
      This deposit is subject to the OwnersBox <a href="https://app.ownersbox.com/wfs/terms" target="_blank" rel="noopener"
        class="termsOfServiceLink"
      >Terms of Service</a>.
    </div>

    <DepositModal />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import ObLoading from '@/components/ObLoading.vue';
import DepositModal from '@/components/modals/DepositModal.vue';
import DepositMethods from './DepositMethods.vue';
import ReasonsToVerify from '@/components/ReasonsToVerify.vue';

export default {
  components: {
    DepositModal,
    ObLoading,
    DepositMethods,
    ReasonsToVerify,
  },

  props: {
    onBoardingStep: {type: Boolean, default: false},
  },

  data() {
    return {
      locationInfo: null,
      checkingValidLocation: true,
      isLocationValid: false,
      isRestricted: true,
      restrictedMessage: '',
      userInfo: null,
      loadingUserData: true,
      loadingUserState: true,
      firstLocationLoad: true,
      locationWatcherId: null,
      isDepositMethodSelected: false,
    };
  },

  created() {
    this.getVerifyState();
  },

  computed: {
    hasNotVerified() {
      return this.userInfo && (this.userInfo.isDepositVerified == null || this.userInfo.evsCheckSSN == true);
    },
  },

  methods: {
    isSelectedMethod(boolean) {
      this.isDepositMethodSelected = boolean;
    },

    // 1. First Check location, and age if already verified
    checkLocation() {
      this.loadingUserData = true;
      ObPaymentApi.isValidLocation()
          .then((response) => {
            this.isLocationValid = true;
            this.isRestricted = false;
            this.locationInfo = response;
            this.loadingUserData = false;
            this.checkingValidLocation = false;
            this.getVerifyUserInfo();
          })
          .catch((error) => {
            const errorMsg = error?.response?.data?.errorMessage ?? 'Error checking location';

            if (errorMsg === 'failed verification') {
              // Show failed verification
              this.isRestricted = true;
              this.restrictedMessage = 'Verification Failed';
            } else if (errorMsg === 'invalid location') {
              // Invalid due to location
              this.isRestricted = true;
              this.isLocationValid = false;
              this.restrictedMessage = 'Invalid Location';
              EventBus.$emit('SHOW_LOCATION_ERROR_MODAL', error?.response?.data?.locationError);
            } else {
              // Invalid due to other reason, show error message from server
              this.isRestricted = true;
              this.isLocationValid = false;
              this.restrictedMessage = errorMsg;
            }
            this.checkingValidLocation = false;
            this.loadingUserData = false;
          });
    },

    // 2. Next Check if EVS verified
    getVerifyUserInfo() {
      this.loadingUserData = true;
      ObPaymentApi.getVerifyUserInfo()
          .then((response) => {
            this.userInfo = response;
            // Onboarding needs to know if user is a kroger affiliate on its deposit screen
            this.$store.commit('setIsKrogerAffiliate', response.isKrogerAffiliate);
            this.loadingUserData = false;
          }).catch((_error) => {
            this.loadingUserData = false;
          });
    },

    getVerifyState() {
      this.loadingUserState = true;
      ObPaymentApi.getVerifyState()
          .then((response) => {
            if (response.state === 'IDPV_VERIFY') {
              this.$router.push('/verifyaccount');
            }
          }).catch((_error) => {
            // Do nothing
          }).finally(() => {
            this.loadingUserState = false;
            this.checkLocation();
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>