<template>
  <Modal v-if="isOpen" v-model="isOpen" :hideCloseButton="false"
    containerStyle="width: 510px; overflow: hidden; padding: 0;"
  >
    <div class="headerContainer" slot="header">
      <div>Gift Card Deposit</div>
    </div>
    <div slot="body">
      <div class="modalBody">You are about to deposit a ${{ giftCardAmount.toFixed(2) }} gift card. Do you wish to continue?</div>
      <ErrorPanel v-if="submitError" :message="submitError" />

      <div class="buttonContainerGiftCard">
        <button class="ob-btn-grey leftBtn" @click="closeDeposit()">Cancel</button>
        <button v-if="waitingForResponse" class="ob-btn rightBtn" disabled>
          <i class="fad fa-spinner-third fa-spin"></i>Submitting...
        </button>
        <button v-else class="ob-btn rightBtn" @click="depositGiftCard()">Deposit</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import EventBus from '@/event-bus';
import Modal from '@/components/Modal.vue';
import ObPaymentApi from '@/api/ObPaymentApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';

export default {
  name: 'ValidateGiftCardModal',

  components: {
    Modal,
    ErrorPanel,
  },

  data() {
    return {
      isOpen: false,
      giftCardAmount: null,
      giftCardNo: null,
      giftCardPin: null,
      waitingForResponse: false,
      submitError: '',
    };
  },

  created() {
    EventBus.$on('OPEN_VALIDATE_GIFT_CARD_MODAL', this.openModal);
  },

  destroyed() {
    EventBus.$off('OPEN_VALIDATE_GIFT_CARD_MODAL', this.openModal);
  },

  methods: {
    openModal(validateCard) {
      this.giftCardAmount = validateCard.amount;
      this.giftCardNo = validateCard.cardNumber;
      this.giftCardPin = validateCard.cardPin;
      this.isOpen = true;
    },

    closeDeposit() {
      this.submitError = '';
      this.isOpen = false;
    },

    depositGiftCard() {
      // Tracking, currently putting in timestamp in place of transaction id
      const tid = String(new Date().getTime());
      const tAmount = parseFloat(this.giftCardAmount.toFixed(2));

      this.waitingForResponse = true;
      const params = {
        cardNumber: this.giftCardNo,
        cardPin: this.giftCardPin,
      };
      ObPaymentApi.depositGiftCard(params)
          .then((response) => {
            this.waitingForResponse = false;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('DEPOSIT_SUBMIT_SUCCESS');

            let firstTimeDeposit = false;
            let tCoupon;
            if (response === 'success-first-deposit') {
              tCoupon = 'FIRST_DEPOSIT';
              firstTimeDeposit = true;
            }
            EventBus.$emit('OPEN_DEPOSIT_MODAL', this.giftCardAmount.toFixed(2), firstTimeDeposit, null);
            this.$Analytics.trackDeposit(tid, tAmount, tCoupon, true);
          }).catch((error) => {
            this.submitFailure(error);
            this.$Analytics.trackDeposit(tid, tAmount, '', false);
          });
    },

    submitFailure(error) {
      if (error?.response?.data) {
        this.submitError = error.response.data;
      } else {
        this.submitError = 'Error, please contact support';
      }
      this.waitingForResponse = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-family: 'Archivo', sans-serif;
}
.headerContainer {
  padding: 40px 40px 8px 40px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.modalBody {
  font-size: 16px;
  line-height: 24px;
  padding: 0 40px 16px 40px;
}
.buttonContainerGiftCard {
  display: flex;
}
button {
  flex: 1;
  margin: 0;
  padding: 15px 30px;
}
.leftBtn {
  border-radius: 0 0 0 5px;
}
.rightBtn {
  border-radius: 0 0 5px 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.errorPanel {
  margin-bottom: 0px;
}
.ob-btn[disabled] {
  background: var(--obcolor-font-secondary);
  color: var(--obcolor-background-2) !important;
  cursor: not-allowed;
}
</style>