<template>
  <div>
    <div v-if="userInfo && !userInfo.hasDeposited && !isDepositing">
      <FirstDepositText />
    </div>
    <div v-if="showAddInfo" class="container">
      <div class="depositTitle">We Need More Info</div>
      <p class="textInfo" style="padding-bottom: 24px;">For a seamless connection to your bank account, please provide the following details.</p>

      <div class="inputText">First Name</div>
      <input v-model="firstName" placeholder="Enter First Name" type="text">

      <div class="inputText">Last Name</div>
      <input v-model="lastName" placeholder="Enter Last Name" type="text">

      <div class="inputText">Date of Birth</div>
      <input v-model="dateOfBirth" placeholder="Enter Date of Birth" type="date"
        max="9999-12-31" :class="theme ==='dark' ? 'dateSelector' : 'dateSelectorLight'"
      >

      <div class="inputText">Email Address</div>
      <input v-model="email" placeholder="Enter Email Address" type="text">

      <div class="inputText">Street Address</div>
      <GoogleAutocompleteStreetAddress id="map" classname="form-control"
        v-model="streetAddress"
        placeholder="Enter Address"
        type="text" @placechanged="updateAddressData" :country="['us']"
        :enable-geolocation="true"
      />

      <div class="addressContainer">
        <div>
          <div class="inputText">City</div>
          <input v-model="city" placeholder="Enter City" type="text">
        </div>

        <div style="position: relative;">
          <div class="inputText">State</div>
          <select v-model="state" :style="state === '-1' ? 'color: var(--obcolor-font-secondary);' : ''">
            <option value="-1" disabled>Select State</option>
            <option v-for="(entry, index) in regionList" :value="entry.abbreviation" :key="index">{{ entry.name }}</option>
          </select>
          <i class="far fa-chevron-down selectArrow"></i>
        </div>
      </div>

      <div class="inputText">Zip Code</div>
      <input v-model="zipCode" @blur="validateZipCode"
        name="zipCode" type="text" :class="errorZipCode ? 'errorBorder' : ''"
        placeholder="Enter Zip Code"
      >
      <ErrorPanel :message="errorZipCode" />

      <div class="inputText">Mobile Phone Number</div>
      <div class="phoneNumberContainer">
        <div v-if="phoneNumber" class="countryCode">+1</div>
        <InputMask v-model="phoneNumber" mask="999-999-9999" placeholder="Enter Phone Number"
          maskChar="" style="flex: 1;" @blur="validateNumber"
          :style="phoneNumber ? 'padding-left: 45px !important;' : ''" :class="errorNumber ? 'errorBorder' : ''"
        />
      </div>
      <ErrorPanel :message="errorNumber" />

      <div class="inputText">Social Security Number</div>
      <input v-model="ssn" v-cleave="cleaveOptionsSSN" @blur="validateSSN"
        placeholder="Enter SSN" type="text" :class="errorSSN ? 'errorBorder' : ''"
      >
      <ErrorPanel :message="errorSSN" />

      <ErrorPanel :message="errorMessage" />

      <button v-if="waitingForResponse" class="ob-btn-grey" disabled>
        <i class="fad fa-spinner-third fa-spin"></i>
        Linking Account...
      </button>
      <button v-else class="ob-btn" :disabled="!canSubmit"
        @click="submitAddAccountData()"
      >
        Continue
      </button>

      <div v-show="isModalVisible" class="modal-mask">
        <div v-show="isModalVisible" id="sdk-container" class="sdkContainer"></div>
      </div>
    </div>

    <div v-else>
      <div v-if="waitingForResponse">
        <ObLoading />
      </div>
      <div v-else-if="userAccounts && userAccounts.length > 0 && !isDepositing">
        <div class="depositTitle">Select Bank</div>
        <div v-for="(account, index) in userAccounts" :key="index" @click="selectAccount(index)"
          class="bankContainer"
        >
          <div class="bankInfo">
            <div class="bankName">{{ account.bankName }}</div>
            <div>Ending with {{ account.accountLast4 }}</div>
          </div>

          <div @click.stop="toggleDropdown(index)" :class="{'deleteButton': true, 'deleteClicked': activeDropdownIndex === index}"></div>

          <div v-if="activeDropdownIndex === index" class="dropDownDelete">
            <div @click.stop="openDeleteAccount(account)" class="deleteBtn">
              <i class="fas fa-trash-alt"></i>
              <div>Delete</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="selectedAccountIndex !== null && isDepositing" class="depositScreen">
        <div class="arrowContainer">
          <a class="backBtn" @click="goBack"><i class="far fa-chevron-left"></i></a>
        </div>
        <h2>{{ userAccounts[selectedAccountIndex].bankName }} Ending with {{ slicedAccountDigits(userAccounts[selectedAccountIndex]) }}</h2>
        <div v-if="userInfo && !userInfo.hasDeposited">
          <FirstDepositText />
        </div>
        <DepositScreen :userInfo="userInfo" :userAccounts="userAccounts[selectedAccountIndex]"
          onlineBanking
        />
      </div>

      <div v-else class="container">
        <img v-if="theme === 'dark'" :src="require('@/assets/deposit/noBank.png')" class="noBankLogo"
          alt="no bank added"
        >
        <img v-else :src="require('@/assets/deposit/noBank_light.png')" class="noBankLogo"
          alt="no bank added"
        >
        <div class="noBankTxt">No Bank Accounts have been added</div>
      </div>
      <div v-if="!isDepositing" @click="addBankInfo" class="ob-btn-2">
        <i class="fal fa-plus"></i>
        Add Bank Account
      </div>
      <div v-show="isCloseModalVisible" class="modal-mask">
        <div v-show="isCloseModalVisible" id="sdk-container" class="closeSDK"></div>
      </div>
    </div>
    <DeleteAccountModal @handleCloseAccount="handleCloseAccount" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import ObLoading from '@/components/ObLoading';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import GoogleAutocompleteStreetAddress from '@/components/GoogleAutocompleteStreetAddress';
import DeleteAccountModal from './DeleteAccountModal.vue';
import DepositScreen from './DepositScreen.vue';
import InputMask from 'vue-input-mask/src/InputMask.vue';
import {mapState} from 'vuex';
import FirstDepositText from './FirstDepositText.vue';

export default {
  components: {
    ObLoading,
    ErrorPanel,
    InputMask,
    DepositScreen,
    DeleteAccountModal,
    GoogleAutocompleteStreetAddress,
    FirstDepositText,
  },

  props: {
    userInfo: {type: Object, default: null},
  },

  data() {
    return {
      userAccounts: null,
      waitingForResponse: false,
      timeoutMs: 1800000,
      redirectTimeout: null,
      activeDropdownIndex: null,
      showAddInfo: false,

      firstName: '',
      lastName: '',
      dateOfBirth: null,
      email: '',
      streetAddress: '',
      city: '',
      state: '-1',
      zipCode: '',
      phoneNumber: '',
      ssn: '',
      errorSSN: '',
      errorNumber: '',
      errorZipCode: '',
      cleaveOptionsSSN: {
        blocks: [9],
        numericOnly: true,
      },
      cleaveOptionsZIP: {
        blocks: [10],
        numericOnly: true,
      },
      isModalVisible: false,
      isCloseModalVisible: false,
      errorMessage: null,
      selectedAccountIndex: null,
      isDepositing: false,
      selectedAccount: null,
      fdCustomerId: null,
      achAccountId: null,
      enrollmentProcessed: false,
    };
  },

  created() {
    this.setPageTimeout();
    this.getFiservAchAccount();
    EventBus.$on('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  destroyed() {
    clearTimeout(this.redirectTimeout);
    EventBus.$off('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  mounted() {
    const script = document.createElement('script');
    script.src = this.$Helpers.getACHScript();
    script.addEventListener('load', this.fiservACHLoaded);
    document.body.appendChild(script);
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    ...mapState(['CP', 'theme']),

    canSubmit() {
      return this.firstName != '' && this.lastName != '' && this.dateOfBirth != null &&
        this.email != '' && this.streetAddress != '' && this.city != '' && this.state != '-1' &&
        this.zipCode != '' && this.phoneNumber.replace(/-/g, '').length == 10 && this.ssn.length == 9;
    },

    regionList() {
      return this.$GeographicInfo.getGeographicInfo();
    },
  },

  watch: {
    zipCode(newVal, oldVal) {
      if (newVal.length > 10) {
        this.zipCode = oldVal;
        return;
      }
      const numCode = newVal.replace(/[^\d-]/g, '');
      if (numCode != newVal) {
        this.zipCode = numCode;
        return;
      }
      this.formatZipCode();
    },
  },

  methods: {
    fiservACHLoaded() {
      const apiKey = process.env.VUE_APP_FISERV_API_KEY;
      const CP = CPSDK(apiKey, true);
      this.$store.commit('setCP', CP);
    },

    getFiservAchAccount() {
      this.waitingForResponse = true;
      ObPaymentApi.getFiservAchAccount()
          .then((response) => {
            this.userAccounts = response.accounts;
            this.waitingForResponse = false;
          }).catch((_error) => {
            this.waitingForResponse = false;
          });
    },

    updateAddressData(addressData, _placeResultData, _id) {
      // Wait for country to change
      this.state = addressData.administrative_area_level_1;
      this.city = addressData.city;
      let zipCode = addressData.postal_code || '';
      zipCode = zipCode.replace(' ', '');
      this.zipCode = zipCode;
      this.streetAddress = addressData.street_address;
    },

    setPageTimeout() {
      const self = this;
      clearTimeout(this.redirectTimeout);
      // Redirect in 30 minutes
      this.redirectTimeout = setTimeout(function() {
        self.redirectToPlayNow();
      }, this.timeoutMs);
    },

    redirectToPlayNow() {
      this.$router.push('/start-playing');
    },

    toggleDropdown(index) {
      this.activeDropdownIndex = this.activeDropdownIndex === index ? null : index;
    },

    addBankInfo() {
      this.errorMessage = null;
      this.showAddInfo = true;
      this.enrollmentProcessed = false;
    },

    submitAddAccountData() {
      this.waitingForResponse = true;
      this.isModalVisible = true;
      this.enrollmentProcessed = false;
      this.setBodyScrollClass(true);
      const extraParams = {
        'firstName': this.firstName,
        'lastName': this.lastName,
        'email': this.email,
        'userPhone[0].number': this.phoneNumber.replace(/-/g, ''),
        'userPhone[0].type': 'MOBILE',
        'street': this.streetAddress,
        'city': this.city,
        'state': this.state,
        'postalCode': this.zipCode,
        'ssn': this.ssn,
        'dob': this.dateOfBirth,
        'genericFlag1': 'N',
      };

      ObPaymentApi.consumerProfile()
          .then((response) => {
            // Create sdkConfiguration object
            const sdkConfiguration = {
              accessToken: response.tokenID,
              fdCustomerId: response.fdCustomerId,
              configId: process.env.VUE_APP_FISERV_CONFIG_ID,
              encryptionKey: response.publicKey,
            };

            this.fdCustomerId = response.fdCustomerId;
            this.achAccountId = response.achAccountId;

            const streamlinedEnrollment = this.CP.StreamlinedEnrollment(sdkConfiguration, extraParams,
                this.enrollmentCompleted);
            streamlinedEnrollment.start('sdk-container');
          }).catch((error) => {
            if (error?.response?.data) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'Failed to connect to the server';
            }
            this.isModalVisible = false;
            this.waitingForResponse = false;
            this.setBodyScrollClass(false);
          });
    },

    async enrollmentCompleted(response) {
      if (response?.responseVerbiage === 'User Cancelled') {
        // Add delay for case where the User Cancelled callback and actual error or success callbacks get called at the same time.
        // This will allow those callbacks to process first and set the enrollmentProcessed flag.
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay
        if (this.enrollmentProcessed) {
          this.completeCallback();
          return;
        }

        await this.updateAccount(
            null,
            null,
            response.responseVerbiage,
            null,
            'ENROLLMENT_ABORTED',
        );
        this.completeCallback();
        return;
      }

      try {
        response = JSON.parse(response);
      } catch (error) {
        this.errorMessage = error.response;
        this.completeCallback();
        return;
      }

      if (response?.transactionStatus === 'APPROVED' && response?.nonce) {
        this.enrollmentProcessed = true;
        this.waitingForResponse = true;
        const transactionId = response.teleCheckDetails?.transactionId ?? null;
        this.addFiservAccount(response.nonce, transactionId, response.referenceTransactionID);
        this.showAddInfo = false;
        this.isModalVisible = false;
        this.setBodyScrollClass(false);
        return;
      }

      if (response?.transactionStatus === 'ERROR') {
        this.enrollmentProcessed = true;
        const transactionId = response.teleCheckDetails?.transactionId ?? null;
        await this.updateAccount(
            transactionId,
            response.referenceTransactionID,
            response.responseVerbiage,
            response.errorDetails,
            'ENROLLMENT_FAILED',
        );
        this.errorMessage = response.responseVerbiage;
        this.completeCallback();
      }
    },

    completeCallback() {
      this.waitingForResponse = false;
      this.isModalVisible = false;
      this.setBodyScrollClass(false);
    },

    addFiservAccount(nonce, transactionId, referenceTransactionId) {
      this.waitingForResponse = true;
      ObPaymentApi.addFiservAccount(nonce, transactionId, referenceTransactionId)
          .then((_response) => {
            this.getFiservAchAccount();
          }).catch((error) => {
            this.waitingForResponse = false;
            this.isModalVisible = false;
            this.setBodyScrollClass(false);
            if (error?.response?.data) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'Failed to add account';
            }
          });
    },

    async updateAccount(transactionId, referenceTransactionId, responseVerbiage, errorDetails, accountStatus) {
      const params = {
        fdCustomerId: this.fdCustomerId,
        transactionId: transactionId,
        referenceTransactionId: referenceTransactionId,
        responseVerbiage: responseVerbiage,
        errorDetails: errorDetails,
        accountStatus: accountStatus,
      };
      try {
        await ObPaymentApi.updateAccount(this.achAccountId, params);
      } catch (_error) {
        // Do nothing
      }
    },

    validateSSN() {
      if (this.ssn.length < 9) {
        this.errorSSN = 'SSN must be 9 digits';
      } else {
        this.errorSSN = '';
      }
    },

    formatZipCode() {
      const zipCode = this.zipCode.replace(/\D/g, '');
      if (zipCode.length > 5) {
        this.zipCode = `${zipCode.slice(0, 5)}-${zipCode.slice(5, 9)}`;
      } else {
        this.zipCode = zipCode;
      }
    },

    validateZipCode() {
      if (this.zipCode.length === 5 || this.zipCode.length === 10) {
        this.errorZipCode = '';
      } else {
        this.errorZipCode = 'Please enter a 5-digit or 5+4 digit zip code';
      }
    },

    selectAccount(index) {
      this.selectedAccountIndex = index;
      this.isDepositing = true;
      this.$emit('isOnlineBankDeposit', this.isDepositing);
    },

    validateNumber() {
      const phoneNumber = this.phoneNumber.replace(/-/g, '');
      if (phoneNumber.length < 10) {
        this.errorNumber = 'Phone number must be 10 digits';
      } else {
        this.errorNumber = '';
      }
    },

    openDeleteAccount(account) {
      this.selectedAccount = account;
      EventBus.$emit('OPEN_DELETE_ACCOUNT_MODAL', account);
      this.toggleDropdown(null);
    },

    handleCloseAccount(response) {
      this.getFiservAchAccount();
      this.isCloseModalVisible = true;
      const sdkConfiguration = {
        accessToken: response.tokenID,
        fdCustomerId: this.selectedAccount.fdCustomerId,
        configId: process.env.VUE_APP_FISERV_CONFIG_ID_CLOSE,
        encryptionKey: response.publicKey,
      };

      const extraParams = {};

      const closeAccount = this.CP.CloseAccount(sdkConfiguration, extraParams, this.completeDeleteAccount);
      closeAccount.start('sdk-container');
    },

    completeDeleteAccount(response) {
      try {
        response = JSON.parse(response);
      } catch (error) {
        this.waitingForResponse = false;
        this.isCloseModalVisible = false;
        this.errorMessage = error.response;
        return;
      }

      if (response.transactionStatus == 'APPROVED') {
        this.isCloseModalVisible = false;
      }

      if (response.transactionStatus == 'ERROR') {
        this.errorMessage = response.responseVerbiage;
        this.selectedAccount = null;
        this.isCloseModalVisible = false;
        this.waitingForResponse = false;
      }
      this.getFiservAchAccount();
    },

    deleteAchAccount() {
      this.waitingForResponse = true;
      ObPaymentApi.deleteAchAccount(this.selectedAccount.id)
          .then((_response) => {
            this.getFiservAchAccount();
          }).catch((error) => {
            if (error?.response?.data) {
              this.errorMessage = error.response.data.errorMessage;
            } else {
              this.errorMessage = 'Failed to delete account';
            }
            this.waitingForResponse = false;
          });
    },

    goBack() {
      this.isDepositing = false;
      this.$emit('isOnlineBankDeposit', this.isDepositing);
    },

    setBodyScrollClass(isOpen) {
      if (isOpen) {
        document.body.classList.add('modal-open');
      } else {
        // Only re-add scrolling if all modals have been closed on the page
        const modalWrappers = document.getElementsByClassName('modal-wrapper');
        if (modalWrappers.length === 0) {
          document.body.classList.remove('modal-open');
        }
      }
    },

    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.dropDownDelete');
      if (dropdown && !dropdown.contains(event.target)) {
        this.activeDropdownIndex = null;
      }
    },

    slicedAccountDigits(account) {
      return account?.accountLast4?.slice(-4);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>