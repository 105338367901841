<template>
  <div class="container">
    <div class="arrowContainer">
      <!-- No Arrows - Keep container for consistent spacing -->
    </div>
    <OnBoardingProgressBar class="title" :customCurrentStep="1" :totalSteps="2" />
    <div v-if="loadingUserData">
      <div><ObLoading :size="90" /></div>
    </div>
    <div v-else-if="isUserNotVerified">
      <h1>Your account has not been verified</h1>
      <div class="buttonContainer">
        <button class="ob-btn-grey" @click="skipVerifyAccount()">Later</button>
        <button class="ob-btn" @click="goToVerify()">Verify Account</button>
      </div>
    </div>
    <div v-else>
      <VerifySuccess onBoardingStep />
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import OnBoardingProgressBar from '@/views/OnBoardingSteps/OnBoardingPages/OnBoardingProgressBar';
import ObLoading from '@/components/ObLoading.vue';
import VerifySuccess from '@/views/VerifyAccount/VerifySuccess.vue';
import {mapState} from 'vuex';

export default {
  components: {
    OnBoardingProgressBar,
    ObLoading,
    VerifySuccess,
  },

  data() {
    return {
      userInfo: null,
      loadingUserData: true,
    };
  },

  created() {
    this.getVerifyUserInfo();
  },

  computed: {
    ...mapState(['showConnections']),

    isUserNotVerified() {
      if (!this.userInfo) {
        return false;
      }
      const hasNotVerified = this.userInfo.isDepositVerified == null || this.userInfo.evsCheckSSN === true;
      const hasFailedVerification = this.userInfo.isDepositVerified === false && !this.userInfo.evsCheckSSN;
      return hasNotVerified || hasFailedVerification;
    },
  },

  methods: {
    skipVerifyAccount() {
      EventBus.$emit('ON_BOARDING_EXIT');
    },

    getVerifyUserInfo() {
      this.loadingUserData = true;
      ObPaymentApi.getVerifyUserInfo()
          .then((response) => {
            this.userInfo = response;
            this.loadingUserData = false;
            // If user is successfully verified, open the Kroger connect modal
            this.checkOpenKrogerConnect();
          }).catch((_error) => {
            this.loadingUserData = false;
          });
    },

    checkOpenKrogerConnect() {
      if (!this.isUserNotVerified && this.userInfo.showConnections && this.userInfo.isKrogerAffiliate) {
        EventBus.$emit('ON_BOARDING_OPEN_KROGER_MODAL');
      }
    },

    goToVerify() {
      EventBus.$emit('ON_BOARDING_BACK');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  h1 {
    margin-top: 50px;
    text-align: center;
  }

  button {
    height: 50px;
  }

  .title {
    padding: 50px 0 10px 0;
  }
}
</style>