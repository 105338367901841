<template>
  <div class="ob-page flexColumn">
    <div class="dashboardContainer">
      <div v-if="loading" class="flexBox">
        <ObLoading />
      </div>
      <template v-else>
        <CompletedPropsHeader v-if="contestState == 'completed'" :contests="filteredPropsContests" :contestState="contestState"
          :completedFilters="leagueFilters"
        />
        <template v-if="contestsLoading || filteredPropsContests.length !== 0">
          <div class="contests">
            <LivePropsContest v-for="(propsEntry, index) in filteredPropsContests" :key="index" :entry="propsEntry"
              :contestState="contestState" class="liveContestContainer" :data-contest-id="propsEntry.id"
            />
          </div>
        </template>
        <div v-else>
          <div class="liveContestContainer noActiveContests">You have no {{ displayState }} Player Picks contests</div>
        </div>
        <!-- Lazy Load Spinner -->
        <div style="height: 100px;">
          <div v-if="contestsLoading" class="pageLoading">
            <em class="fad fa-spinner-third fa-spin"></em>
          </div>
        </div>
      </template>
    </div>
    <PropsHowToPlay />
    <PayoutStructureModal />
  </div>
</template>

<script>
import ObLoading from '@/components/ObLoading';
import ObPropsApi from '@/api/ObPropsApi';
import LivePropsContest from './LiveContests/PlayerPicks/LivePropsContest.vue';
import CompletedPropsHeader from './LiveContests/PlayerPicks/CompletedPropsHeader.vue';
import moment from 'moment';
import PropsHowToPlay from '@/views/Props/LineupBuilder/PropsHowToPlay.vue';
import PayoutStructureModal from '@/components/modals/Props/PayoutStructureModal.vue';
import PropsContestsMixin from '@/mixins/PropsContestsMixin';
import {CanceledError} from 'axios';

export default {
  mixins: [PropsContestsMixin],
  components: {
    ObLoading,
    LivePropsContest,
    CompletedPropsHeader,
    PropsHowToPlay,
    PayoutStructureModal,
  },

  data() {
    return {
      leagueFilters: {leagueCount: 25, month: moment().month(), year: moment().year(), leagueType: 'all'},
      nextPage: 0,
      limit: 25,
      loading: true,
      contestsLoading: false,
      redraw: 0,
      propContestsAbortController: null,
    };
  },

  props: {
    contestState: {type: String, default: null},
  },

  watch: {
    contestState(newVal, oldVal) {
      // set defaults when state changes
      this.leagueFilters.leagueCount = 25;
      this.resetPagination();
      this.loadPlayerPicksContests(this.contestState, this.getOffset(), this.getLimit());
    },
    'leagueFilters.leagueCount'(newVal) {
      this.resetPagination();
      this.loadPlayerPicksContests(this.contestState, this.getOffset(), this.getLimit());
    },
    'leagueFilters.month'(newVal) {
      this.resetPagination();
      this.loadPlayerPicksContests(this.contestState, this.getOffset(), this.getLimit());
    },
    'leagueFilters.year'(newVal) {
      this.resetPagination();
      this.loadPlayerPicksContests(this.contestState, this.getOffset(), this.getLimit());
    },
  },

  computed: {
    displayState() {
      if (this.contestState === 'active') {
        return 'live';
      }
      return this.contestState;
    },

    filteredPropsContests() {
      const contestStateToPageState = {
        UPCOMING: 'upcoming',
        ACTIVE: 'active',
        COMPLETED: 'completed',
        FINALIZED: 'completed',
        CANCELLED: 'completed',
      };
      return this.propsContests.filter((contest) => this.contestState === contestStateToPageState[contest.state]);
    },
  },

  created() {
    this.loadPlayerPicksContests(this.contestState, this.getOffset(), this.getLimit());
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.lastCheckedInterval);
  },

  methods: {
    loadPlayerPicksContests(state, offset, limit) {
      this.contestsLoading = true;

      if (this.propContestsAbortController) {
        this.propContestsAbortController.abort();
      }
      this.propContestsAbortController = new AbortController();

      const params = {state, offset, limit};
      if (this.leagueFilters.leagueCount == 'More') {
        params.year = this.leagueFilters.year;
        params.month = this.leagueFilters.month + 1;
      }
      return ObPropsApi.getPropContests(params, {
        signal: this.propContestsAbortController.signal,
      })
          .then((data) => {
            if (data.length > 0) {
              this.appendPropsContests(data);
              this.nextPage += this.limit;
            }
            this.loading = false;
            this.contestsLoading = false;
          })
          .catch((error) => {
            if (error instanceof CanceledError) {
              return;
            }
            this.loading = false;
            this.contestsLoading = false;
          });
    },

    handleScroll() {
      // Calculate how far the user has scrolled from the top of the page
      const scrollPosition = window.scrollY + window.innerHeight + 10;
      const fullHeight = document.body.scrollHeight;

      if (scrollPosition >= fullHeight) {
        this.onScrollToBottom();
      }
    },

    onScrollToBottom() {
      this.loadPlayerPicksContests(this.contestState, this.nextPage, this.getLimit());
    },

    getLimit() {
      return this.limit;
    },

    getOffset() {
      return this.nextPage;
    },

    resetPagination() {
      this.nextPage = 0;
      this.limit = 25;
      this.loading = true;
      this.clearPropsContests();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.liveContestHeaderContainer,
.feedsContainer {
  transition: top 0.3s ease-out;
}

.liveContestHeaderContainer {
  position: sticky;
  z-index: 80;
  padding-bottom: 3px;
  background: var(--obcolor-background-page);
}

.liveContestHeader {
  margin-bottom: 0px;
  background: var(--obcolor-background-3);
}

.liveContestContainer {
  display: flex;
  flex-direction: row;
  background: var(--obcolor-background-6);
  margin-bottom: 8px;
}

.noActiveContests {
  height: max-content;
  padding: 28px 15px;
  font-size: 14px;
  border-radius: 8px;
  color: var(--obcolor-font-secondary);
}

.pageLoading {
  left: 0;
  right: 0;
  height: 20px;
  text-align: center;
  padding: 6px;
  opacity: 0.5;
  bottom: 0;
  z-index: 1;
}

.flexBox {
  flex: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.dashboardContainer {
  flex: 1 1 0%;
}
</style>
