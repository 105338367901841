<template>
  <div :class="onBoardingStep ? '' : 'ob-page'">
    <div v-if="loadingVerify">
      <ObLoading :size="80" style="margin-top: 60px;" />
    </div>
    <div v-else-if="!loadingVerify && accountIsVerified">
      <VerifySuccess />
    </div>
    <div v-else class="container">
      <h1>Verify Account</h1>
      <p class="textInfo">Complete the form below to verify your account.</p>

      <div class="inputText">First Name</div>
      <input placeholder="Enter your first name" type="text" v-model="firstName">

      <div class="inputText">Last Name</div>
      <input placeholder="Enter your last name" type="text" v-model="lastName">

      <div class="inputText">Date of Birth</div>
      <input placeholder="Enter your date of birth" type="date" v-model="dateOfBirth"
        max="9999-12-31" :class="theme ==='dark' ? '' : 'dateSelectorLight'"
        @blur="checkStateMinAge"
      >
      <ErrorPanel class="errorPanel" :message="errorDOB" />

      <div class="inputText">Street Address</div>
      <GoogleAutocompleteStreetAddress id="map" classname="form-control"
        v-model="address"
        placeholder="Enter Address"
        type="text" @placechanged="updateAddressData" :country="['ca', 'us']"
        :enable-geolocation="true"
      />

      <div class="inputText">City</div>
      <input placeholder="Enter City" v-model="city" type="text">

      <div class="countryContainer">
        <div style="position: relative;">
          <div class="inputText">Country</div>
          <select v-model="country" class="selectCountry" :style="country === '-1' ? 'color: var(--obcolor-font-secondary);' : ''">
            <option value="-1" disabled selected>Select Country</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
          <i class="far fa-chevron-down selectArrow"></i>
        </div>

        <div style="position: relative;">
          <div class="inputText">{{ stateProvinceLabel }}</div>
          <select v-model="region"
            :disabled="country === '-1'"
            :style="region === '-1' ? 'color: var(--obcolor-font-secondary);' : ''"
            @blur="checkStateMinAge"
          >
            <option value="-1" disabled selected>Select {{ stateProvinceLabel }}</option>
            <option v-for="(entry, index) in regionList" :value="entry.name" :key="index">{{ entry.name }}</option>
          </select>
          <i class="far fa-chevron-down selectArrow"></i>
        </div>
      </div>

      <div class="inputText">{{ zipPostalCodeLabel }}</div>
      <input v-if="country === 'US'"
        v-model="postalCode"
        type="text"
        inputmode="numeric"
        maxlength="5"
        :placeholder="zipPostalCodeLabel"
        name="zipCode"
        @blur="checkZip"
      >
      <input v-else
        v-model="postalCode"
        :placeholder="zipPostalCodeLabel"
        name="postalCode"
        type="text"
        maxlength="6"
        @blur="checkPostal"
      >
      <ErrorPanel class="errorPanel" :message="errorPostalCode" />

      <div v-if="country === 'US'" class="inputText">Social Security Number</div>
      <input v-if="country === 'US'"
        v-model="ssn"
        v-cleave="cleaveOptionsSSN"
        placeholder="Enter SSN (9 Digits)"
        @blur="checkValidSSN"
      >

      <ErrorPanel class="errorPanel" :message="errorSSN" />
      <ErrorPanel class="errorPanel" :message="errorMessage" />

      <button v-if="loading" class="ob-btn-grey" disabled>
        <i class="fad fa-spinner-third fa-spin"></i>
        Verifying...
      </button>
      <button v-else class="ob-btn" :disabled="!canVerify || errorDOB != null || errorPostalCode != null || errorSSN != null"
        @click="verifyAccount()"
      >
        Continue
      </button>

      <VerifyFailedModal v-if="failedModalOpen" v-model="failedModalOpen" :checkSSN="false"
        :loading="loading" :onBoardingStep="onBoardingStep" :verifyStatus="verifyStatus"
      />
    </div>
  </div>
</template>

<script>
import VerifyFailedModal from './VerifyFailedModal.vue';
import VerifySuccess from './VerifySuccess.vue';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import GoogleAutocompleteStreetAddress from '@/components/GoogleAutocompleteStreetAddress';
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';
import {mapState} from 'vuex';
import ObLoading from '@/components/ObLoading';
import ObResponsiblePlayApi from '@/api/ObResponsiblePlayApi';

export default {
  components: {
    VerifyFailedModal,
    ErrorPanel,
    ObLoading,
    VerifySuccess,
    GoogleAutocompleteStreetAddress,
  },

  props: {
    onBoardingStep: {type: Boolean, default: false},
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      address: '',
      postalCode: '',
      country: '-1',
      region: '-1',
      city: '',
      ssn: '',
      loading: false,
      loadingVerify: true,
      failedModalOpen: false,
      errorMessage: null,
      errorSSN: null,
      errorPostalCode: null,
      errorDOB: null,
      accountIsVerified: false,
      verifyStatus: null,
      cleaveOptionsSSN: {
        blocks: [9],
        numericOnly: true,
      },
      stateSettings: null,
      defaultMinAge: 18,
    };
  },

  computed: {
    ...mapState(['userId', 'theme']),

    stateProvinceLabel() {
      if (this.country === 'US' ) {
        return 'State';
      }
      if (this.country === 'CA') {
        return 'Province';
      }
      return 'State / Province';
    },

    zipPostalCodeLabel() {
      if (this.country === 'US' ) {
        return 'Zip Code';
      }
      if (this.country === 'CA') {
        return 'Postal Code';
      }
      return 'Zip / Postal Code';
    },

    regionList() {
      return this.$GeographicInfo.getGeographicInfo(this.country);
    },

    formattedDateOfBirth() {
      if (!this.dateOfBirth) {
        return null;
      }

      const [year, month, day] = this.dateOfBirth.split('-');
      return `${year}-${parseInt(month)}-${parseInt(day)}`;
    },

    canVerify() {
      if (this.country === 'CA') {
        return this.canVerifyCA;
      } else if (this.country === 'US') {
        return this.canVerifyCA && this.ssn?.length == 9;
      }
      return false;
    },

    canVerifyCA() {
      return this.firstName?.trim() && this.lastName?.trim() && this.address?.trim() &&
        this.country != '-1' && this.region != '-1' && this.city?.trim() &&
        this.postalCode?.trim() && this.dateOfBirth;
    },
  },

  watch: {
    country() {
      this.region = '-1';
      this.postalCode = '';
      this.ssn = '';
      this.errorPostalCode = null;
      this.errorSSN = null;
      this.errorMessage = null;
    },

    postalCode(newVal) {
      if (!newVal) {
        return;
      }
      if (this.country == 'US') {
        this.postalCode = newVal.replace(/\D/g, '');
      }
    },
  },

  created() {
    this.getVerifyState();
    this.getStateSettings();
  },

  methods: {
    updateAddressData(addressData, _placeResultData, _id) {
      if (addressData.country == 'United States') {
        this.country = 'US';
      } else {
        this.country = 'CA';
      }
      // Wait for country to change
      this.$nextTick().then(() => {
        this.region = this.$GeographicInfo.abbreviationToName(this.country, addressData.administrative_area_level_1);
        this.city = addressData.city;
        let postalCode = addressData.postal_code || '';
        postalCode = postalCode.replace(' ', '');
        this.postalCode = postalCode;
        this.address = addressData.street_address;
      });
    },

    getStateSettings() {
      ObResponsiblePlayApi.getStateSettings().then((response)=>{
        this.stateSettings = response.settings;
      });
    },

    getVerifyState() {
      this.accountIsVerified = false;
      this.loadingVerify = true;
      ObPaymentApi.getVerifyState()
          .then((response) => {
            this.verifyStatus = response.state;
            if (this.verifyStatus === 'VERIFIED' && this.onBoardingStep) {
              this.accountIsVerified = true;
              EventBus.$emit('ON_BOARDING_NEXT');
            } else if (this.verifyStatus === 'VERIFIED') {
              this.accountIsVerified = true;
            } else if (this.verifyStatus === 'FAILED' || this.verifyStatus === 'IDPV_VERIFY') {
              this.failedModalOpen = true;
            }
            this.loadingVerify = false;
          }).catch((_error) => {
            this.loadingVerify = false;
          });
    },

    verifyAccount() {
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        street: this.address,
        city: this.city,
        country: this.country,
        dateOfBirth: this.formattedDateOfBirth,
      };

      if (this.country == 'US') {
        params.zipCode = this.postalCode;
        params.state = this.region;
        params.ssn = this.ssn;
      } else {
        params.postalCode = this.postalCode;
        params.province = this.region;
      }

      if (this.errorSSN || this.errorPostalCode || this.errorDOB) {
        return;
      }

      this.loading = true;
      this.errorMessage = null;
      ObPaymentApi.verifyAccountGeoComply(params)
          .then((response) => {
            this.loading = false;
            this.accountIsVerified = false;
            if (response.status === 'PASS') {
              this.$Analytics.trackVerify(this.userId, true);
              // If verified during onboarding go to next step, if verified in the app show the account verified message
              if (this.onBoardingStep) {
                EventBus.$emit('ON_BOARDING_NEXT');
              } else {
                this.accountIsVerified = true;
              }
            } else {
              // Verify Failed
              this.verifyStatus = response.state;
              if (this.verifyStatus === 'USER_VERIFY') {
                this.errorMessage = response.message;
              } else {
                this.failedModalOpen = true;
                this.$Analytics.trackVerify(this.userId, false);
              }
            }
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
          })
          .catch((error) => {
            if (error?.errorMessage) {
              this.errorMessage = error.errorMessage;
            } else {
              this.errorMessage = 'There was an error verifying your account, please try again or contact customer service';
            }
            this.loading = false;
          });
    },

    checkPostal() {
      const postalCode = this.postalCode.replace(/\s+/g, '');
      const isValidLength = postalCode.length === 6;
      const isValidFormat = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(postalCode);
      if (isValidLength && isValidFormat) {
        this.errorPostalCode = null;
        return true;
      }

      this.errorPostalCode = 'Invalid postal code format. Please enter in the format A1A 1A1.';
      return false;
    },

    checkZip() {
      if (this.postalCode.length == 5) {
        this.errorPostalCode = null;
        return true;
      }

      this.errorPostalCode = 'Invalid Zip Code length, Zip Code should be 5 digits long';
      return false;
    },

    checkValidSSN() {
      if (this.ssn.length == 9) {
        this.errorSSN = null;
        return true;
      }
      this.errorSSN = 'Invalid SSN length, please enter a 9-digit SSN';
      return false;
    },

    checkStateMinAge() {
      let state = null;

      if (this.stateSettings) {
        state = this.stateSettings.find((setting) => setting.id === (this.region?.toUpperCase() || null));
      }
      const minAge = state ? state.minAge : this.defaultMinAge;

      const birthDateObject = this.$moment(this.dateOfBirth, 'YYYY-MM-DD');
      const curDateObject = this.$moment();
      const isOldEnough = curDateObject.diff(birthDateObject, 'years') >= minAge;

      if (isOldEnough) {
        this.errorDOB = null;
        return true;
      }

      this.errorDOB = `You must be at least ${minAge} years old to verify your account.`;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 705px;
  margin: 0 auto;
  font-family: 'Archivo', sans-serif;

  h1,
  p {
    margin: 0;
  }

  p {
    line-height: 21px;
  }

  h1 {
    padding: 50px 0 30px 0;
    font-size: 24px;
    line-height: 10px;
    font-weight: bold;
  }

  .selectArrow {
    position: absolute;
    top: 45px;
    right: 20px;
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
  }

  .countryContainer {
    display: flex;
    gap: 20px;

    .selectCountry {
      width: 180px;
    }
    > div {
      display: flex;
      flex-direction: column;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }

  input {
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
    padding: 15px 25px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .ob-btn[disabled] {
    background: var(--obcolor-font-secondary);
    color: var(--obcolor-background-2) !important;
    cursor: not-allowed;
  }

  button {
    margin-top: 50px;
    height: 60px;
    width: 100%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
    font-size: 20px;
    cursor: pointer;
    padding: 0;
  }

  .inputText {
    padding: 2px 0 10px 0;
    font-size: 14px;
  }

  .textInfo {
    padding: 0 0 25px 0;
  }

  select {
    padding: 15px 25px;
    margin-bottom: 15px;
    border: 2px solid var(--obcolor-input-border);
    background: var(--obcolor-input-bg);
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  option {
    color: var(--obcolor-font-primary);
  }

  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-year-field:focus {
    background-color: var(--obcolor-ob-blue);
    outline: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px var(--obcolor-bg) inset;
    -webkit-text-fill-color: var(--obcolor-font-primary);
  }
}

.errorPanel {
  margin-bottom: 10px;
}

.dateSelectorLight::-webkit-calendar-picker-indicator {
  filter: invert(35%) !important;
  cursor: pointer;
}
</style>